import {useState, forwardRef} from "react";
import {Stack, Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alerts({
    severityAlert,
    alertMessage,
    openStatus,
    setOpenToFalse,
}) {
    const [open, setOpen] = useState(openStatus);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        setOpenToFalse();
    };

    return (
        <Stack spacing={2} sx={{width: "100%"}}>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={severityAlert}
                    sx={{width: "100%"}}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
