import {useEffect, useState} from "react";
import {Button, TextField, Paper} from "@mui/material";
import {Box} from "@mui/system";

function ItemPricingComponent({pricing, removeRow, pricingId, updatePricing}) {
    const [itemPrice, setItemPrice] = useState(pricing.Price);
    const [itemDimension, setItemDimension] = useState(pricing.Dimensions);
    const [itemDiscount, setItemDiscount] = useState(pricing.Discount);

    function setDimensions(input) {
        if (input !== null) {
            const dimensions = document.getElementById(
                `dimensions-${pricing.PricingId}`
            );
            setItemDimension(dimensions.value);
        }
    }

    function setPrice(input) {
        if (input !== null) {
            const price = document.getElementById(`price-${pricing.PricingId}`);
            setItemPrice(price.value);
        }
    }

    function setDiscount(input) {
        if (input !== null) {
            const discount = document.getElementById(
                `discount-${pricing.PricingId}`
            );
            setItemDiscount(Number(discount.value));
        }
    }

    useEffect(() => {}, [itemPrice, itemDimension, itemDiscount]);

    return (
        <div className="admin-item-pricing">
            <Paper variant="outlined">
                <Box marginTop={4}>
                    <label>Dimension:</label>
                    <br />
                    <TextField
                        id={`dimensions-${pricing.PricingId}`}
                        value={itemDimension}
                        name="Dimension"
                        onChange={(e) => setDimensions(e.target.value)}
                    ></TextField>
                    <br />
                    <label>Pricing: </label>
                    <br />
                    <TextField
                        id={`price-${pricing.PricingId}`}
                        type="number"
                        value={itemPrice}
                        name="Price"
                        onChange={(e) => setPrice(e.target.value)}
                    ></TextField>
                    <br />
                    <label>Discounts</label>
                    <br />
                    <TextField
                        id={`discount-${pricing.PricingId}`}
                        type="number"
                        value={itemDiscount}
                        name="Discount"
                        onChange={(e) => setDiscount(e.target.value)}
                    ></TextField>
                    <br />
                    <Button
                        type="button"
                        onClick={() =>
                            updatePricing(
                                pricingId,
                                itemDimension,
                                itemPrice,
                                itemDiscount
                            )
                        }
                    >
                        Update Pricing
                    </Button>
                    <br />
                    <br />
                    <Button
                        type="button"
                        onClick={() => removeRow(pricing.PricingId)}
                    >
                        Remove Row
                    </Button>
                </Box>
            </Paper>
        </div>
    );
}

export default ItemPricingComponent;
