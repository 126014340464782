import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './components/Main'
import Header from './components/Header';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import NotePrint from './components/NotePrint';
import OriginalPaint from './components/OriginalPaint';
import PrintGallery from './components/PrintGallery';
import AdminLogin from './components/admin/AdminLogin';
import AdminPage from './components/admin/AdminPage';
import OrderComplete from './components/OrderComplete';
import { ShoppingList } from './components/shoppingcart/ShoppingList';
import '@fontsource/roboto-slab/300.css';
import '@fontsource/roboto-slab/400.css';
import '@fontsource/roboto-slab/500.css';
import '@fontsource/roboto-slab/700.css';
import './App.css';

function App() {
  const [showCart, setShowCart] = useState(false);

  const updateShowCart = (update) => {
    if(update === true) {
      setShowCart(true);
      return;
    }
    setShowCart(false);
  }

  useEffect(() => {
  },[showCart]);

  return (
    <div className="App">
      <BrowserRouter>
        <Header updateCart={updateShowCart}/>
        <ShoppingList showCart={showCart}/>
        <Routes>
          <Route exact path="/" element={<Main/>} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/original" element={<OriginalPaint />} />
          <Route path="/login" element={<AdminLogin />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/printgallery" element={<PrintGallery />} />
          <Route path='/noteprints' element={<NotePrint />} />
          <Route path='/order' element={<OrderComplete />} />
          <Route path='/cart' element={<ShoppingList showCart={true} />} />
          <Route path="*" Component={Main} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
