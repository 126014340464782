import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
  //DEV
  // domain="dev-ecyb56onla6ixuvz.us.auth0.com"
  // clientId="EhMdvm5vNLYakK0FjP8vk2kqTXTOeTGM"
  //PROD
  domain="dev-twxxyjvsscvwmxrj.us.auth0.com"
  clientId="5QWUDhfNEqQu9HyybmJLJ8aUhqpLwa6Y"
  authorizationParams={{
    redirect_uri: window.location.origin
  }}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Auth0Provider>
);