import IndividualItem from "./IndividualItem";

function DisplayItems({ itemList }) {
    return (
        <>
            {itemList.length > 0 && (
                <div className="singleitems">
                    {itemList.map((item) => {
                        return (
                            <IndividualItem
                                key={item.ItemId}
                                shopItem={item}
                                onAdd={""}
                            />
                        );
                    })}
                </div>
            )}
            {itemList.length === 0 && (
                <>
                    <h3>Unfortunately, there are no items here</h3>
                </>
            )}
        </>
    );
}

export default DisplayItems;
