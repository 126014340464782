export const useValidateCartData = (cart, items) => {
    var priceGood = true;
    cart.forEach((cartItem) => {
        const storedItem = items.filter((item) => item._id == cartItem._id);
        if (storedItem.length > 0) {
            const pricing = storedItem[0].Pricing.filter(
                (pricing) => pricing.PricingId === cartItem.Pricing[0].PricingId
            );
            if (pricing.length > 0) {
                if (pricing[0].Price !== cartItem.Pricing[0].Price) {
                    priceGood = false;
                }
                if (pricing[0].Discount !== cartItem.Pricing[0].Discount) {
                    priceGood = false;
                }
            }
        }
    });
    return priceGood;
};
