import {useState, useEffect} from "react";
import axios from "axios";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function useSetItemsFromAxios(type) {
    const [data, setData] = useState([]);
    useEffect(() => {
        const getItems = async () => {
            await axios
                .get(`${API_ENDPOINT}/items`)
                .then((data) => {
                    if (type !== "" && type !== undefined) {
                        setData(
                            JSON.parse(JSON.stringify(data.data)).filter(
                                (item) => item.Type === type
                            )
                        );
                        return;
                    }
                    setData(null);
                })
                .catch((error) => {});
        };
        getItems();
    }, [type]);
    return data;
}

function useGetSpecialItems() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const getItems = async () => {
            await axios
                .get(`${API_ENDPOINT}/items`)
                .then((data) => {
                    const items = JSON.parse(JSON.stringify(data.data));
                    if (items.length > 0) {
                        setData(
                            items.filter((item) => {
                                if (item.Special === true) {
                                    return item;
                                }
                            })
                        );
                        return;
                    }
                    setData([]);
                })
                .catch((error) => {});
        };
        getItems();
    }, []);
    return data;
}

function useGetAdminItems() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const getItems = async () => {
            await axios
                .get(`${API_ENDPOINT}/items`)
                .then((data) => {
                    setData(JSON.parse(JSON.stringify(data.data)));
                })
                .catch((error) => {});
        };
        getItems();
    }, []);
    return data;
}

function useGetWeeklySpecialText() {
    const [data, setData] = useState("");
    useEffect(() => {
        const getBodyText = async () => {
            await axios
                .get(`${API_ENDPOINT}/specialtext`)
                .then((data) => {
                    setData(JSON.parse(JSON.stringify(data.data)));
                })
                .catch((error) => {});
        };
        getBodyText();
    }, []);
    return data;
}

export {
    useSetItemsFromAxios,
    useGetSpecialItems,
    useGetAdminItems,
    useGetWeeklySpecialText,
};
