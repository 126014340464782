import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

function ShoppingCart({ cartList, handleQuantityChange, handleDelete }) {
    return (
        <>
            {cartList.map((item) => {
                return (
                    <div className="cart-container">
                        <div className="cart-item-img">
                            <img src={item.ImageUrl} alt={item.Name} />
                        </div>
                        <div className="cart-item-header">
                            <h5 className="cart-items-header">{item.Name}</h5>
                            <p>{item.Dimension}</p>
                        </div>
                        <div className="cart-item-body">
                            <p>
                                $
                                {(
                                    item.Pricing[0].Price -
                                    (item.Pricing[0].Discount === 0
                                        ? 0
                                        : (item.Pricing[0].Price *
                                              item.Pricing[0].Discount) /
                                          100)
                                ).toFixed(2)}
                            </p>
                            <div className="cart-item-qt">
                                <li
                                    className="quantity-box-minus"
                                    onClick={() =>
                                        handleQuantityChange(
                                            "update",
                                            "subtract",
                                            item
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faMinus} />
                                </li>
                                <p>{item.Quantity}</p>
                                <li
                                    className="quantity-box-plus"
                                    onClick={() =>
                                        handleQuantityChange(
                                            "update",
                                            "add",
                                            item
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </li>
                            </div>
                            <li
                                onClick={() => handleDelete("delete", "", item)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </li>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

export default ShoppingCart;
