import {Carousel} from "react-responsive-carousel";
import {useGetSpecialItems} from "./shoppingcart/RetrieveItemsFromAxios";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Paper, Box} from "@mui/material";
import SpecialItems from "./SpecialItems";

const gallerySlideShowArray = [
    "https://res.cloudinary.com/dtrqcot5a/image/upload/v1696131875/imageJQR-2_cpztcl.webp",
    "https://res.cloudinary.com/dtrqcot5a/image/upload/v1696131875/imageJQR-3_vexyop.webp",
    "https://res.cloudinary.com/dtrqcot5a/image/upload/v1696131876/imageJQR-4_zw1c8y.webp",
    "https://res.cloudinary.com/dtrqcot5a/image/upload/v1696131876/imageJQR-5_vnpi62.webp",
    "https://res.cloudinary.com/dtrqcot5a/image/upload/v1696131874/imageJQR-6_wo2unq.webp",
    "https://res.cloudinary.com/dtrqcot5a/image/upload/v1696131874/The_Survivor_bot1ab.webp",
    "https://res.cloudinary.com/dtrqcot5a/image/upload/v1696131876/Jack_s_Cove.Shipyard_Farm_zf0boz.webp",
    "https://res.cloudinary.com/dtrqcot5a/image/upload/v1696131875/CWM-Whaling-Museum_vt6kmy.webp",
    "https://res.cloudinary.com/dtrqcot5a/image/upload/v1696131877/Low_Tide-Little_BayMed_jqoghl.webp",
];

function Main() {
    const specialItemsList = useGetSpecialItems();

    return (
        <main id="main">
            <Grid2
                container
                marginX={{xs: 1, sm: 2, md: 0, lg: 0}}
                marginY={{xs: 2, sm: 4, md: 6, lg: 6, xl: 8}}
            >
                <Grid2
                    xs={12}
                    sm={12}
                    md={6}
                    display="flex"
                    justifyContent={{
                        xs: "center",
                        sm: "center",
                        md: "center",
                        lg: "right",
                    }}
                    alignItems="center"
                >
                    <Box align="center">
                        <Box>
                            <img
                                style={{
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                }}
                                src="https://res.cloudinary.com/dtrqcot5a/image/upload/v1696131874/Artist_mdhawp.webp"
                                alt="artist pictured while on sailboat with camera"
                            />
                        </Box>
                        <Box
                            textAlign="center"
                            paddingX={{sm: 5, md: 10, lg: 15, xl: 30}}
                            paddingTop={2}
                        >
                            <p>
                                Arthur Moniz was born in New Bedford,
                                Massachusetts. He attended Massachusetts College
                                of Art in Boston. His paintings depict a wide
                                range of interests, from Florida to Japan and of
                                course the New England area. His style of
                                painting is a combination of graphite drawing
                                with transparent watercolor washes over the
                                highly detailed renderings. He also worked in a
                                varity of mediums including oils and pen & ink
                                and airbrushing.
                            </p>
                        </Box>
                    </Box>
                </Grid2>
                <Grid2
                    xs={12}
                    sm={12}
                    md={4}
                    marginY={{xs: 1, sm: 2, md: 4, lg: 8}}
                >
                    <Box align="center">
                        <Box padding={{xs: 1, sm: 2}}>
                            <img
                                src="https://res.cloudinary.com/dtrqcot5a/image/upload/v1696217005/gallery/udtfxromht7p3hgkotbt.webp"
                                alt="header with quote from artist"
                                width="50%"
                            />
                            <Box paddingTop={{xs: 1, sm: 2, md: 4}}>
                                <h2
                                    style={{
                                        fontFamily: "lucida calligraphy",
                                        fontWeight: 400,
                                        fontStyle: "italic",
                                    }}
                                >
                                    I try to convey a feeling
                                    <br />
                                    of quiet and calm
                                    <br />
                                    in a world of noise and fast pace...
                                    <br />
                                    a peacefulness
                                    <br />
                                    that comes from being alone
                                    <br />
                                    but not lonely
                                </h2>
                                <h3
                                    style={{
                                        fontFamily: "lucida calligraphy",
                                        fontSize: 16,
                                        fontWeight: 400,
                                    }}
                                >
                                    <br />- Arthur Moniz
                                </h3>
                            </Box>
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
            <Grid2
                marginX={{xs: 1, sm: 2, md: 4, lg: 16, xl: 32}}
                marginTop={{xs: 1, sm: 2, md: 4, lg: 12}}
                marginBottom={{xs: 1, sm: 2, md: 4, lg: 14}}
            >
                <Carousel
                    autoPlay="true"
                    dynamicHeight="true"
                    centerMode="true"
                    emulateTouch="true"
                    infiniteLoop="true"
                    width="100%"
                    axis=""
                    styles={styles}
                >
                    {gallerySlideShowArray.map((image) => {
                        return (
                            <div>
                                <img
                                    src={image}
                                    alt="show this"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        );
                    })}
                </Carousel>
            </Grid2>
            <Grid2
                container
                marginX={{xs: 1, sm: 2, md: 4, lg: 8}}
                marginY={{xs: 2, sm: 4, md: 6, lg: 8}}
            >
                <Grid2
                    xs={12}
                    sm={6}
                    md={7}
                    marginY={{xs: 2}}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box align="center">
                        <img
                            src="https://res.cloudinary.com/dtrqcot5a/image/upload/v1696217005/gallery/nvtupcevbjblcc5wulry.webp"
                            alt="country road painting"
                            width="60%"
                            height="60%"
                        />
                    </Box>
                </Grid2>
                <Grid2
                    xs={12}
                    sm={6}
                    md={4}
                    marginY={{xs: 1}}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Paper variant="outlined">
                        <Box align="center">
                            <img
                                src="https://res.cloudinary.com/dtrqcot5a/image/upload/v1696217005/gallery/rnmppnioy7zmmmppvcbj.webp"
                                alt="information about store opening"
                                width="100%"
                                height="100%"
                            />
                        </Box>
                    </Paper>
                </Grid2>
            </Grid2>
            <SpecialItems specialItem={specialItemsList.at(0)} />
        </main>
    );
}

export default Main;
