import ItemCollection from "./shoppingcart/ItemCollection";

function NotePrint() {
    return (
        <main>
            <div className="original-gallery">
                <h3>Note Cards</h3>
                <h5>
                    Notecards come in packages of 5 for $12.50, or packages of
                    10 for $18.98.
                </h5>
                <ItemCollection itemType={"noteprint"} />
            </div>
        </main>
    );
}

export default NotePrint;
