import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Paper, Box } from "@mui/material";

function OrderComplete() {
    const [searchParams] = useSearchParams();
    const [orderId, setOrderId] = useState("");

    useEffect(() => {
        const id =
            searchParams.get("orderId") === null
                ? ""
                : searchParams.get("orderId");
        if (id !== "") {
            setOrderId(id);
        }
    }, [searchParams]);
    return (
        <main>
            {orderId.length === 0 && <h3>Error You are not allowed here!</h3>}
            {orderId.length > 0 && (
                <Grid2
                    marginX={{ xs: 1, sm: 2, md: 4, lg: 40 }}
                    marginY={{ xs: 2, sm: 4, md: 8, lg: 64 }}
                >
                    <Paper elevation={8}>
                        <Box padding={{ xs: 1, sm: 2, md: 4 }} align="center">
                            <h3>
                                Thank you for shopping with us your order
                                identification number is : {orderId}
                            </h3>
                        </Box>
                    </Paper>
                </Grid2>
            )}
        </main>
    );
}

export default OrderComplete;
