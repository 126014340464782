import {useEffect, useState} from "react";
import CustomSearch from "../search/CustomSearch";
import DisplayItems from "./DisplayItems";
import {useSetItemsFromAxios, useGetAdminItems} from "./RetrieveItemsFromAxios";

function ItemCollection({itemType}) {
    const itemList = useSetItemsFromAxios(itemType);
    const itemsForSearch = useGetAdminItems();
    const [searchedItem, setSearchedItems] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    function searchFunction(input) {
        if (input === null) {
            setSearchedItems(false);
            return;
        }
        setSearchedItems(true);
        setFilteredData(input);
    }

    useEffect(() => {}, [filteredData, searchedItem]);

    return (
        <>
            {itemList.length > 0 && (
                <div className="original-gallery-items">
                    <CustomSearch
                        itemList={itemsForSearch}
                        searchFunction={searchFunction}
                    />
                    {!searchedItem && <DisplayItems itemList={itemList} />}
                    {searchedItem && <DisplayItems itemList={filteredData} />}
                </div>
            )}
            {itemList.length === 0 && (
                <h1>There does not seem to be any Items</h1>
            )}
        </>
    );
}

export default ItemCollection;
