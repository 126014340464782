import {useEffect, useState} from "react";
import ItemEditing from "./ItemEditing";
import TextEditing from "./TextEditing";
import {useGetAdminItems} from "../shoppingcart/RetrieveItemsFromAxios";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";
import {
    Grid,
    Box,
    Paper,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
} from "@mui/material";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const options = [
    {value: "original", label: "Original"},
    {value: "printgallery", label: "Boats"},
    {value: "fishingboat", label: "Fishing Boats"},
    {value: "noteprint", label: "Note Print"},
    {value: "whaler", label: "Whaler and Ships"},
    {value: "coastal", label: "Coastal Scenes"},
    {value: "lighthouses", label: "Lighthouses"},
    {value: "whalermorgan", label: "Whaler Charles W. Morgan"},
    {value: "nature", label: "Nature & Wildlife"},
    {value: "cranberry", label: "Cranberry Bogs"},
    {value: "country", label: "Country Scenes"},
    {value: "garden", label: "Garden & Floral"},
    {value: "buttonwood", label: "Buttonwood Park"},
    {value: "azores", label: "Azores"},
    {value: "canterbury", label: "Canterbury Shaker Village"},
    {value: "florida", label: "Florida & Japan"},
    {value: "pen", label: "Pen & Ink"},
    {value: "martha", label: "Martha's Vineyard & Nantucket"},
    {value: "fallriver", label: "Fall River & Dartmouth"},
    {value: "newbedford", label: "New Bedford & Fairhaven"},
    {value: "matta", label: "Mattapoisett & Westport"},
    {value: "marion", label: "Marion & Acushnet"},
    {value: "gifts", label: "Other Gifts"},
    {
        value: "community",
        label: "Communities, Building, Churches, Schools, Misc",
    },
];

function AdminPage() {
    const itemList = useGetAdminItems();
    const [editList, setEditList] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showItemEdit, setShowItemEdit] = useState(false);
    const [showPainting, setShowPainting] = useState(false);
    const [showTextEdit, setShowTextEdit] = useState(false);
    const [itemEdit, setItemEdit] = useState();
    const {user, isAuthenticated} = useAuth0();

    function onClick(add, edit) {
        setShowAdd(false);
        setShowEdit(false);
        if (add) {
            setShowAdd(true);
        }
        if (edit) {
            setShowEdit(true);
            setItemEdit(itemList.find((item) => Number(item.ItemId) == 1));
        }
        if (showTextEdit) {
            setShowTextEdit(false);
        }
    }

    useEffect(() => {}, [showPainting]);

    function onTypeChange(evt) {
        if (evt !== undefined) {
            setShowPainting(
                options.filter((type) => type.value === evt.target.value)
            );
            setEditList(
                itemList.filter((item) => item.Type === evt.target.value)
            );
        }
    }

    function onItemChange(evt) {
        if (evt !== undefined) {
            setItemEdit(
                itemList.find(
                    (item) => Number(item.ItemId) == Number(evt.target.value)
                )
            );
        }
    }

    function editItem(type) {
        if (type === "Edit") {
            setShowItemEdit(true);
        }
        if (type === "Delete") {
            setShowItemEdit(false);
            if (window.confirm("Would you like to delete this item?")) {
                axios
                    .delete(`${API_ENDPOINT}/item`, {
                        headers: {
                            "Content-Type": "application/JSON",
                            "x-api-key": user === undefined ? "" : user.sub,
                        },
                        data: itemEdit,
                    })
                    .then((response) => {})
                    .catch((error) => {});
            }
        }
    }

    useEffect(() => {}, [showItemEdit, itemEdit]);

    return (
        <main>
            {isAuthenticated && user !== null && (
                <Grid container alignItems="center">
                    <Grid
                        xs={12}
                        marginTop={{xs: 4, sm: 6, md: 12, lg: 24}}
                        marginBottom={{xs: 4, sm: 20, md: 32, lg: 72}}
                        marginX={{xs: 2, sm: 6, md: 12, lg: 24}}
                    >
                        <Paper elevation={8} align="center">
                            <Box padding={{xs: 1, sm: 2}}>
                                <div className="admin-page">
                                    <h3>Administrative Page</h3>
                                    <div className="admin-page-login-success">
                                        <h4>Welcome {user.name}!</h4>
                                        <Box margin={2}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    onClick(false, false);
                                                    setShowTextEdit(
                                                        showTextEdit === true
                                                            ? false
                                                            : true
                                                    );
                                                }}
                                            >
                                                Edit Weekly Special Text
                                            </Button>
                                        </Box>
                                        <Box margin={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() =>
                                                    onClick(true, false)
                                                }
                                            >
                                                Add Items
                                            </Button>
                                        </Box>
                                        <Box margin={2}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    onClick(false, true)
                                                }
                                            >
                                                Edit/Delete Items
                                            </Button>
                                        </Box>
                                        <div>
                                            {showAdd && (
                                                <Box
                                                    marginX={{
                                                        xs: 1,
                                                        sm: 6,
                                                        md: 24,
                                                        lg: 24,
                                                        xl: 100,
                                                    }}
                                                >
                                                    <ItemEditing
                                                        addItem={true}
                                                        editItem={false}
                                                        item={null}
                                                    />
                                                </Box>
                                            )}
                                            {showEdit && (
                                                <Box
                                                    marginX={{
                                                        xs: 1,
                                                        sm: 6,
                                                        md: 24,
                                                        lg: 24,
                                                        xl: 100,
                                                    }}
                                                >
                                                    <FormControl fullWidth>
                                                        <InputLabel id="select-painting-type">
                                                            Item Type
                                                        </InputLabel>
                                                        <Select
                                                            labelId="select-painting-type"
                                                            id="select-the-painting-type"
                                                            label="Painting-type"
                                                            onChange={
                                                                onTypeChange
                                                            }
                                                        >
                                                            {options.map(
                                                                (option) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            onClick={() =>
                                                                                showPainting ===
                                                                                true
                                                                                    ? setShowPainting(
                                                                                          false
                                                                                      )
                                                                                    : null
                                                                            }
                                                                            onChange={options.find(
                                                                                (
                                                                                    type
                                                                                ) =>
                                                                                    type.value ==
                                                                                    option.value
                                                                            )}
                                                                            value={
                                                                                option.value
                                                                            }
                                                                        >
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                    {showPainting && (
                                                        <Box marginY={2}>
                                                            <FormControl
                                                                fullWidth
                                                            >
                                                                <InputLabel id="select-painting">
                                                                    Item
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="select-painting"
                                                                    id="select-the-painting"
                                                                    label="Painting"
                                                                    onChange={
                                                                        onItemChange
                                                                    }
                                                                >
                                                                    {editList.map(
                                                                        (
                                                                            item
                                                                        ) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={
                                                                                        item.ItemId
                                                                                    }
                                                                                    onClick={() =>
                                                                                        showItemEdit ===
                                                                                        true
                                                                                            ? setShowItemEdit(
                                                                                                  false
                                                                                              )
                                                                                            : null
                                                                                    }
                                                                                    onChange={editList.find(
                                                                                        (
                                                                                            listItem
                                                                                        ) =>
                                                                                            Number(
                                                                                                listItem.ItemId
                                                                                            ) ==
                                                                                            Number(
                                                                                                item.ItemId
                                                                                            )
                                                                                    )}
                                                                                    value={
                                                                                        item.ItemId
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.Type
                                                                                    }{" "}
                                                                                    -{" "}
                                                                                    {
                                                                                        item.Name
                                                                                    }
                                                                                </MenuItem>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    )}
                                                    <Box marginTop={2}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() =>
                                                                editItem("Edit")
                                                            }
                                                        >
                                                            Edit Item
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() =>
                                                                editItem(
                                                                    "Delete"
                                                                )
                                                            }
                                                        >
                                                            Delete Item
                                                        </Button>
                                                    </Box>
                                                    {showItemEdit && (
                                                        <ItemEditing
                                                            addItem={false}
                                                            editItem={true}
                                                            item={itemEdit}
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            {showTextEdit && (
                                                <div>
                                                    <label>
                                                        Enter Body text for
                                                        weekly special:
                                                    </label>
                                                    <br />
                                                    <TextEditing />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            )}
            {!isAuthenticated && <h2>You do not have access to this page!</h2>}
        </main>
    );
}

export default AdminPage;
