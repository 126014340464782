import {useShoppingListUpdater} from "./ShoppingList";
import {FormControl, NativeSelect, Modal, Box, Button} from "@mui/material";
import {useState} from "react";

const reload = () => {
    window.location.reload();
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const styleMobile = {
    position: "absolute",
    width: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    p: 1,
};

function IndividualItem({shopItem}) {
    const {UpdateShoppingList} = useShoppingListUpdater();
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    function addNewItem(item, pricingId) {
        if (item.Pricing.length > 1) {
            var pricing = item.Pricing.filter(
                (price) => price.PricingId == pricingId.value
            );
            item.Pricing = pricing;
        }
        UpdateShoppingList("add", "add", item);
        reload();
    }

    function changeSelected(e) {
        const selectedValue = document.querySelector(`#${e.target.id}`);
        selectedValue.value = e.target.value;
    }

    return (
        <div className="card">
            <div className="card-img">
                <img
                    onClick={() => handleOpen()}
                    src={shopItem.ImageUrl}
                    alt={shopItem.Name}
                />
            </div>
            <div className="card-info">
                <p className="text-title">{shopItem.Name}</p>
                <p className="text-body">{shopItem.Description}</p>
            </div>
            <div className="card-footer">
                {shopItem.Pricing.length === 1 && (
                    <>
                        {shopItem.Pricing.map((pricing) => {
                            return (
                                <p
                                    key={pricing.PricingId}
                                    id="itemPricing"
                                    value={pricing.PricingId}
                                >{`${pricing.Dimensions} - $${(
                                    pricing.Price -
                                    (pricing.Price * pricing.Discount) / 100
                                ).toFixed(2)}`}</p>
                            );
                        })}
                    </>
                )}
                {shopItem.Pricing.length > 1 && (
                    <FormControl>
                        <NativeSelect
                            defaultValue={1}
                            id={shopItem.Name.replaceAll(" ", "")}
                            onChange={changeSelected}
                        >
                            {shopItem.Pricing.map((pricing) => {
                                return (
                                    <option
                                        key={pricing.PricingId}
                                        value={pricing.PricingId}
                                    >
                                        {pricing.Dimensions} - $
                                        {(
                                            pricing.Price -
                                            (pricing.Price * pricing.Discount) /
                                                100
                                        ).toFixed(2)}
                                    </option>
                                );
                            })}
                        </NativeSelect>
                    </FormControl>
                )}
                {!shopItem.Available && (
                    <p>
                        <b>Sold</b>
                    </p>
                )}
                {shopItem.Available && (
                    <div className="card-button">
                        <svg
                            onClick={() =>
                                addNewItem(
                                    shopItem,
                                    document.querySelector(
                                        `#${shopItem.Name.replaceAll(" ", "")}`
                                    ) === null
                                        ? 1
                                        : document.querySelector(
                                              `#${shopItem.Name.replaceAll(
                                                  " ",
                                                  ""
                                              )}`
                                          )
                                )
                            }
                            className="svg-icon"
                            viewBox="0 0 20 20"
                        >
                            <path d="M17.72,5.011H8.026c-0.271,0-0.49,0.219-0.49,0.489c0,0.271,0.219,0.489,0.49,0.489h8.962l-1.979,4.773H6.763L4.935,5.343C4.926,5.316,4.897,5.309,4.884,5.286c-0.011-0.024,0-0.051-0.017-0.074C4.833,5.166,4.025,4.081,2.33,3.908C2.068,3.883,1.822,4.075,1.795,4.344C1.767,4.612,1.962,4.853,2.231,4.88c1.143,0.118,1.703,0.738,1.808,0.866l1.91,5.661c0.066,0.199,0.252,0.333,0.463,0.333h8.924c0.116,0,0.22-0.053,0.308-0.128c0.027-0.023,0.042-0.048,0.063-0.076c0.026-0.034,0.063-0.058,0.08-0.099l2.384-5.75c0.062-0.151,0.046-0.323-0.045-0.458C18.036,5.092,17.883,5.011,17.72,5.011z"></path>
                            <path d="M8.251,12.386c-1.023,0-1.856,0.834-1.856,1.856s0.833,1.853,1.856,1.853c1.021,0,1.853-0.83,1.853-1.853S9.273,12.386,8.251,12.386z M8.251,15.116c-0.484,0-0.877-0.393-0.877-0.874c0-0.484,0.394-0.878,0.877-0.878c0.482,0,0.875,0.394,0.875,0.878C9.126,14.724,8.733,15.116,8.251,15.116z"></path>
                            <path d="M13.972,12.386c-1.022,0-1.855,0.834-1.855,1.856s0.833,1.853,1.855,1.853s1.854-0.83,1.854-1.853S14.994,12.386,13.972,12.386z M13.972,15.116c-0.484,0-0.878-0.393-0.878-0.874c0-0.484,0.394-0.878,0.878-0.878c0.482,0,0.875,0.394,0.875,0.878C14.847,14.724,14.454,15.116,13.972,15.116z"></path>
                        </svg>
                    </div>
                )}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={() => {
                        if (window.innerWidth < 700) {
                            return styleMobile;
                        }
                        return style;
                    }}
                    align="center"
                >
                    <img src={shopItem.ImageUrl} alt={shopItem.Description} />
                    <Button onClick={handleClose}>Close</Button>
                </Box>
            </Modal>
        </div>
    );
}

export default IndividualItem;
