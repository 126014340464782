import ItemPage from "./ItemPage";

function ItemEditing({addItem, editItem, item}) {
    return (
        <div>
            {addItem && (
                <div>
                    <ItemPage item={"newItem"} />
                </div>
            )}
            {editItem && (
                <div>
                    <ItemPage item={item} />
                </div>
            )}
        </div>
    );
}

export default ItemEditing;
