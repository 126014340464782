import {useState} from "react";
import axios from "axios";
import {Box, Paper, Grid} from "@mui/material";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const config = {
    headers: {
        "Content-Type": "application/JSON",
    },
};

function ContactUs() {
    const [emailed, setEmailed] = useState(false);
    const [itemQuestion, setItemQuestion] = useState(false);
    const [contactFormData, setContactFormData] = useState({
        firstName: "",
        lastName: "",
        company: "",
        emailAddress: "",
        item: "",
        userText: "",
        contactType: "",
    });

    const sendEmail = () => {
        if (contactFormData == null) {
            return;
        }
        axios
            .post(
                `${API_ENDPOINT}/contactme`,
                JSON.stringify(contactFormData),
                config
            )
            .then()
            .catch();
        setEmailed(true);
    };

    const onChange = (evt) => {
        const key = evt.target.name;
        const value = evt.target.value;
        setContactFormData((oldData) => ({...oldData, [key]: value}));
    };

    function onRadioChange(value) {
        setItemQuestion(value);
    }

    const onSubmit = () => {
        sendEmail();
    };
    return (
        <main>
            <Grid container alignItems="center">
                <Grid xs={12} margin={{xs: 1, sm: 2, md: 4, lg: 24}}>
                    <Paper elevation={8} align="center">
                        <Box padding={{xs: 1, sm: 2}}>
                            <h2>Contact us!</h2>
                            <div>
                                <p>
                                    Need to contact us about a specific painting
                                    or have any other questions?
                                </p>
                                <p>
                                    Use our contact form and we will try to
                                    return your questions in a timely manner.
                                </p>
                                {!emailed && (
                                    <form id="contactForm" onSubmit={onSubmit}>
                                        <label>First Name:</label>
                                        <br />
                                        <input
                                            type="text"
                                            value={contactFormData.firstName}
                                            className="input"
                                            name="firstName"
                                            onChange={onChange}
                                            required
                                        ></input>
                                        <br />
                                        <label>Last Name:</label>
                                        <br />
                                        <input
                                            type="text"
                                            value={contactFormData.lastName}
                                            className="input"
                                            name="lastName"
                                            onChange={onChange}
                                            required
                                        ></input>
                                        <br />
                                        <label>Company / Organization:</label>
                                        <br />
                                        <input
                                            type="text"
                                            value={contactFormData.company}
                                            className="input"
                                            name="company"
                                            onChange={onChange}
                                        ></input>
                                        <br />
                                        <label>Email Address:</label>
                                        <br />
                                        <input
                                            type="email"
                                            value={contactFormData.emailAddress}
                                            className="input"
                                            name="emailAddress"
                                            onChange={onChange}
                                            required
                                        ></input>
                                        <br />
                                        <label>
                                            Question about a specific item?
                                        </label>
                                        <br />
                                        <label>No</label>
                                        <input
                                            type="radio"
                                            value={itemQuestion}
                                            name="itemQuestion"
                                            onChange={() =>
                                                onRadioChange(false)
                                            }
                                        ></input>
                                        <label>Yes</label>
                                        <input
                                            type="radio"
                                            value={itemQuestion}
                                            name="itemQuestion"
                                            onChange={() => onRadioChange(true)}
                                        ></input>
                                        <br />
                                        {itemQuestion && (
                                            <>
                                                <label>Item:</label>
                                                <br />
                                                <input
                                                    type="text"
                                                    value={contactFormData.item}
                                                    className="input"
                                                    name="item"
                                                    onChange={onChange}
                                                    required
                                                ></input>
                                                <br />
                                                <label>Item Question:</label>
                                                <br />
                                                <input
                                                    type="text"
                                                    value={
                                                        contactFormData.userText
                                                    }
                                                    className="input"
                                                    name="userText"
                                                    onChange={onChange}
                                                    required
                                                ></input>
                                                <br />
                                            </>
                                        )}
                                        <br />
                                        <label>Comments and Questions:</label>
                                        <br />
                                        <input
                                            type="textarea"
                                            value={contactFormData.contactType}
                                            className="input"
                                            name="contactType"
                                            onChange={onChange}
                                            required
                                        ></input>
                                        <br />
                                        <br />
                                        <button
                                            className="button"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                )}
                                {emailed && <h3>Email Sent!</h3>}
                            </div>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </main>
    );
}

export default ContactUs;
