import {React, useEffect, useRef, useState} from "react";
import "../App.css";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {useAuth0} from "@auth0/auth0-react";
import {Box} from "@mui/material";

function Header({updateCart}) {
    const [showCart, setShowCart] = useState(false);
    const currentLocation = useRef("");
    const {isAuthenticated} = useAuth0();
    const location = useLocation();
    const [isDropDownChecked, setIsDropDownChecked] = useState(false);

    useEffect(() => {
        if (currentLocation.current !== location.pathname) {
            updateCart(false);
            setShowCart(false);
            setIsDropDownChecked(false);
        }
        currentLocation.current = location.pathname;
    }, [location.pathname]);

    function ShowCustomerCart() {
        if (window.innerWidth < 640) {
            window.location = "/cart";
            return;
        }
        if (!showCart) {
            updateCart(true);
            setShowCart(true);
            return;
        }
        if (showCart) {
            updateCart(false);
            setShowCart(false);
        }
    }

    return (
        <nav className="navigation-bar">
            <Box marginLeft={{xs: 0, sm: 0, md: 0, lg: 10, xl: 15}}>
                <img
                    src="https://res.cloudinary.com/dtrqcot5a/image/upload/v1696217005/gallery/jcfvqw9biekhrsenbobz.webp"
                    alt="gallery logo"
                    className="navigation-logo"
                    style={{
                        height: "7.5em",
                    }}
                />
            </Box>
            <ul className="navigation-bar-items">
                <input
                    type="checkbox"
                    id="checkbox_toggle"
                    onClick={() => {
                        isDropDownChecked === false
                            ? setIsDropDownChecked(true)
                            : setIsDropDownChecked(false);
                    }}
                    checked={isDropDownChecked}
                />
                <label
                    htmlFor="checkbox_toggle"
                    className="navigation-collapse"
                >
                    &#9776;
                </label>
                <div className="navigation-bar-menu">
                    {isAuthenticated && (
                        <li>
                            <Link to="/admin">Admin</Link>
                        </li>
                    )}
                    {window.innerWidth > 820 && (
                        <li className="navigation-bar-shop-services">
                            <Link to="/">Home</Link>
                        </li>
                    )}
                    {window.innerWidth < 820 && (
                        <>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                        </>
                    )}
                    <li>
                        <Link to="/about">About us</Link>
                    </li>
                    <li>
                        <Link to="/original">Originals</Link>
                    </li>
                    <li>
                        <Link to="/printgallery">Print Gallery</Link>
                    </li>
                    <li>
                        <Link to="/noteprints">Note Cards</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact us</Link>
                    </li>
                    <li onClick={() => ShowCustomerCart()}>
                        <FontAwesomeIcon icon={faShoppingCart} />
                    </li>
                </div>
            </ul>
        </nav>
    );
}

export default Header;
