import {useState, useEffect} from "react";
import ShoppingCart from "./ShoppingCart";
import PayPalButton from "./PaypalButton";
import {useValidateCartData} from "./CartComponents";
import {useGetAdminItems} from "./RetrieveItemsFromAxios";

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const randomId = function (length = 20) {
    return Math.random()
        .toString(36)
        .substring(2, length + 2);
};

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function ShoppingList({showCart}) {
    const [customerList, setCustomerList] = useState([]);
    const [itemUpdated, setItemUpdated] = useState(false);
    const {UpdateShoppingList} = useShoppingListUpdater();
    const items = useGetAdminItems();
    const validatedCart = useValidateCartData(customerList, items);
    if (getCookie("UserLocal") === "") {
        setCookie("UserLocal", randomId(), 7);
        localStorage.clear();
    }
    useEffect(() => {
        const storage = JSON.parse(localStorage.getItem("shoppingCartList"));
        setCustomerList(storage === null ? [] : storage);
        setItemUpdated(false);
    }, [itemUpdated]);

    function handleQuantityChange(type, quantityType, item) {
        UpdateShoppingList(type, quantityType, item);
        setItemUpdated(true);
    }

    function handleDelete(type, quantityType, item) {
        UpdateShoppingList(type, quantityType, item);
        setItemUpdated(true);
    }

    return (
        <div>
            {showCart === true && (
                <div className="cart-dropdown">
                    {customerList.length > 0 && (
                        <>
                            <ShoppingCart
                                key={(customerList.length += 1)}
                                cartList={customerList}
                                handleQuantityChange={handleQuantityChange}
                                handleDelete={handleDelete}
                            />
                            {
                                <p className="cart-total">{`Total is : $${customerList
                                    .map((item) => {
                                        return (
                                            (item.Pricing[0].Price -
                                                (item.Pricing[0].Discount === 0
                                                    ? 0
                                                    : (item.Pricing[0].Price *
                                                          item.Pricing[0]
                                                              .Discount) /
                                                      100)) *
                                            item.Quantity
                                        );
                                    })
                                    .reduce((accumulator, a) => {
                                        return accumulator + a;
                                    }, 0)
                                    .toFixed(2)}`}</p>
                            }
                            {validatedCart && <PayPalButton />}
                            {validatedCart === false && (
                                <h3>
                                    You are not allowed to checkout as your
                                    pricing is corrupted, please clear your
                                    cache
                                </h3>
                            )}
                        </>
                    )}
                    {customerList.length === 0 && (
                        <div className="empty-message">
                            <p>The cart is empty, why not add some stuff?</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

const useShoppingListUpdater = () => {
    const UpdateShoppingList = (type, quantityType, newItem) => {
        var oldList =
            JSON.parse(localStorage.getItem("shoppingCartList")) === null
                ? []
                : JSON.parse(localStorage.getItem("shoppingCartList"));
        if (oldList.length > 0) {
            const itemInList = oldList.filter(
                (item) =>
                    item.ItemId === newItem.ItemId &&
                    item.Pricing[0].PricingId === newItem.Pricing[0].PricingId
            );
            if (itemInList.length > 0 && type === "add") {
                type = "update";
                quantityType = "add";
            }
        }
        var deleteId = 0;
        switch (type) {
            case "add":
                newItem.UniqueId = Date.now();
                newItem.Quantity = 1;
                oldList.push(newItem);
                break;
            case "update":
                oldList = oldList.map((item) => {
                    if (
                        item.ItemId === newItem.ItemId &&
                        item.Pricing[0].PricingId ===
                            newItem.Pricing[0].PricingId
                    ) {
                        if (quantityType === "subtract") {
                            if (item.Quantity === 1) {
                                deleteId = newItem.UniqueId;
                                return {
                                    ...item,
                                    Quantity: (item.Quantity = -1),
                                };
                            }
                            return {...item, Quantity: (item.Quantity -= 1)};
                        }
                        return {...item, Quantity: (item.Quantity += 1)};
                    }
                    return item;
                });
                break;
            case "delete":
                deleteId = newItem.UniqueId;
                break;
            default:
                break;
        }
        if (deleteId !== 0) {
            oldList = oldList.filter((item) => item.UniqueId !== deleteId);
        }
        updateLocalStorage(oldList);
    };
    return {UpdateShoppingList};
};

function useGetShoppingList() {
    const storage = JSON.parse(localStorage.getItem("shoppingCartList"));
    return storage;
}

const updateLocalStorage = (list) => {
    localStorage.setItem("shoppingCartList", JSON.stringify(list));
};

export {useShoppingListUpdater, useGetShoppingList, ShoppingList};
