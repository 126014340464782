import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";
import {useGetShoppingList} from "./ShoppingList";
import {useNavigate} from "react-router-dom";

//DEV PAYPAL INFO
// const initialOptions = {
//     "client-id":
//         "ARxW2e6klJqoaYOUN0UxvgVoLHtvo2zgRo70rfpprkAugpcAsdWl7KGpwRx7Ex97d0y-cS2BnP8ecI_k",
//     commit: true,
//     currency: "USD",
//     components: "buttons",
//     "disable-funding": "card,venmo,paylater",
//     intent: "capture",
// };

//PROD PAYPAL INFO
const initialOptions = {
    "client-id":
        "Ab7Jtxjg6l25GdnLppW0Pf9hul71xgASI3TA0kBaAH-UmBsXC0q4fM2K25jIRFblUYHZrBpn9Rgwjt2q",
    commit: true,
    currency: "USD",
    components: "buttons",
    "disable-funding": "venmo,paylater",
    intent: "capture",
};

export default function PayPalButton() {
    const customerList = useGetShoppingList();
    const navigate = useNavigate();

    return (
        <>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={{
                        layout: "vertical",
                        color: "gold",
                        shape: "pill",
                        label: "paypal",
                    }}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: `${customerList
                                            .map((item) => {
                                                return (
                                                    (item.Pricing[0].Price -
                                                        (item.Pricing[0]
                                                            .Discount === 0
                                                            ? 0
                                                            : (item.Pricing[0]
                                                                  .Price *
                                                                  item
                                                                      .Pricing[0]
                                                                      .Discount) /
                                                              100)) *
                                                    item.Quantity
                                                );
                                            })
                                            .reduce((accumulator, a) => {
                                                return accumulator + a;
                                            }, 0)
                                            .toFixed(2)}`,
                                        currency_code: "USD",
                                        breakdown: {
                                            item_total: {
                                                currency_code: "USD",
                                                value: `${customerList
                                                    .map((item) => {
                                                        return (
                                                            (item.Pricing[0]
                                                                .Price -
                                                                (item.Pricing[0]
                                                                    .Discount ===
                                                                0
                                                                    ? 0
                                                                    : (item
                                                                          .Pricing[0]
                                                                          .Price *
                                                                          item
                                                                              .Pricing[0]
                                                                              .Discount) /
                                                                      100)) *
                                                            item.Quantity
                                                        );
                                                    })
                                                    .reduce(
                                                        (accumulator, a) => {
                                                            return (
                                                                accumulator + a
                                                            );
                                                        },
                                                        0
                                                    )
                                                    .toFixed(2)}`,
                                            },
                                        },
                                    },
                                    items: customerList.map((item) => {
                                        return JSON.parse(
                                            JSON.stringify({
                                                unit_amount: {
                                                    currency_code: "USD",
                                                    value:
                                                        item.Pricing[0].Price -
                                                        (item.Pricing[0]
                                                            .Discount === 0
                                                            ? 0
                                                            : (item.Pricing[0]
                                                                  .Price *
                                                                  item
                                                                      .Pricing[0]
                                                                      .Discount) /
                                                              100),
                                                },
                                                quantity: `${item.Quantity}`,
                                                name: `${item.Name}`,
                                                description: `${item.Pricing[0].Dimensions}`,
                                                category: "PHYSICAL_GOODS",
                                            })
                                        );
                                    }),
                                },
                            ],
                        });
                    }}
                    onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                            localStorage.clear();
                            navigate(`/order?orderId=${details.id}`);
                        });
                    }}
                />
            </PayPalScriptProvider>
        </>
    );
}
