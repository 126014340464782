import ItemCollection from "./shoppingcart/ItemCollection";

function OriginalPaint() {
    return (
        <main id="main">
            <div className="original-gallery">
                <div className="original-gallery-items">
                    <ItemCollection itemType={"original"} />
                </div>
            </div>
        </main>
    );
}

export default OriginalPaint;
