import Grid2 from "@mui/material/Unstable_Grid2";
import {Paper, Box} from "@mui/material";

var fontSizes = {sm: 10, md: 16, lg: 20};

function AboutUs() {
    return (
        <main>
            <Grid2
                container
                marginX={{xs: 1, sm: 2, md: 4, lg: 8}}
                marginY={{xs: 2, sm: 4, md: 8, lg: 16}}
                fontSize={fontSizes}
            >
                <Grid2
                    xs={12}
                    sm={4}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginBottom={{xs: 2, sm: 2, md: 0}}
                >
                    <Box align="center">
                        <img
                            src="https://res.cloudinary.com/dtrqcot5a/image/upload/v1696217235/gallery/t9zdhonmzw757jzgmuzy.webp"
                            alt="buddies painting"
                            width="55%"
                        />
                    </Box>
                </Grid2>
                <Grid2
                    xs={12}
                    sm={8}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Paper align="center" elevation={4}>
                        <Box padding={{xs: 1, sm: 4}}>
                            <article>
                                Mr. Moniz was born in New Bedford, MA in 1945
                                <br />
                                <br />
                                He attended school in New Bedford and then went
                                to Massachusetts College of Art and the Museum
                                of Fine Arts in Boston. His paintings depict a
                                wide range of interests from Florida , Japan,
                                Azores, and the New England area, where he lived
                                with his Wife and business partner Cheryl. His
                                other passions are his family 2 children and 4
                                grandchildren.
                                <br />
                                <br />
                                His style of painting is a combination of
                                graphic drawing with transparent watercolor
                                washes over the highly detailed renderings. His
                                style of “graphite and watercolors” has evolved
                                over the years and creates images which are soft
                                and subtle yet shows remarkable details and
                                textures. He also works in oils and pen and ink
                                to create works of art with same attention to
                                fine details and textures of the subjects.
                                <br />
                                <br />
                            </article>
                        </Box>
                    </Paper>
                </Grid2>
            </Grid2>
            <Grid2
                container
                marginX={{xs: 1, sm: 2, md: 4, lg: 8}}
                marginTop={{xs: 2, sm: 4, md: 8, lg: 16}}
                marginBottom={{xs: 2, sm: 2, md: 4, lg: 4}}
                fontSize={fontSizes}
            >
                <Grid2 xs={12}>
                    <Paper elevation={4}>
                        <Box padding={{xs: 1, sm: 2}} align="center">
                            <br />
                            <h3>
                                <u>Education</u>
                            </h3>
                            <br />
                            Massachusetts College of Art 1962-1965
                            <br />
                            School of the Museum of Fine Arts , Boston, MA
                            <br />
                            <br />
                            <h3>
                                <u>Major Exhibitions</u>
                            </h3>
                            <ul>
                                <li>
                                    Commission for official painting for the New
                                    Bedford Whaling Museum “Lagoda Centennial”
                                    2016
                                </li>
                                <li>
                                    American Society Marine Artists 17th
                                    National Exhibition, Williamsburg, 475
                                    entries 111 selected “Mending the Nets”
                                    chosen 2016
                                </li>
                                <li>
                                    One Man invitationalShow: St. Miguel, Azores
                                    sponsored by the Portuguese Government 2015
                                </li>
                                <li>
                                    Commission for official painting for New
                                    Bedford Whaling Museum “Charles W. Morgan
                                    Homecoming Gala/38th Voyage” 2014
                                </li>
                                <li>
                                    Awarded Signature Membership: American
                                    Society of Marine Artist 2013
                                </li>
                                <li>
                                    Drawn from New Bedford, : A Retrospective 45
                                    years at New Bedford Whaling Museum
                                    2010-2011
                                </li>
                                <li>
                                    White House: Ornament Selection for National
                                    Christmas Tree New Bedford National Park
                                    artist selection 2007
                                </li>
                                <li>
                                    New Bedford Whaling Museum, Azorean Maritime
                                    Society 2006 Discovering Your Roots, Azores,
                                    Portugal 2002 (Solo)
                                </li>
                                <li>
                                    Vineyards, Waterways and Landscapes Russell
                                    Gallery at the Westport Rivers Vineyard &
                                    Winery 2006 (Solo)
                                </li>
                                <li>
                                    RJD House, An Evening on the Bay at Silver
                                    Gull 2004
                                </li>
                                <li>
                                    Memories of the Azores, Faial & Sao Miguel
                                    2003
                                </li>
                                <li>Mystic Seaport, Mystic. Conn. 2002</li>
                                <li>
                                    Midnight on the Forecastle New Bedford
                                    Whaling Museum, Moby Dick ”The Chase” 2001
                                </li>
                                <li>
                                    Canterbury Shaker Museum, Canterbury, NH
                                    2000
                                </li>
                                <li>
                                    Boston Symphony, Boston Symphony Hall
                                    Boston, MA 1997
                                </li>
                                <li>
                                    Nature’s Images Alfred J. Walker Fine
                                    Art-The Barn 1995 (Solo)
                                </li>
                                <li>
                                    New Bedford Treasures, Rotch Jones Duff
                                    House & Garden Museum New Bedford 1993
                                    (Solo)
                                </li>
                                <li>
                                    Alfred J. Walker Fine Art, Boston,MA (Solo)
                                    1993
                                </li>
                                <li>
                                    Down to the Sea, New Bedford Whaling Museum,
                                    New Bedford Gentle Solitude, Alfred J.
                                    Walker Fine Art, Boston,MA 1992 (Solo)
                                </li>
                                <li>
                                    Meiji-Mura Museum, Nagoya, Japan (solo) 1992
                                </li>
                                <li>
                                    Arthur Moniz, Views of America & Japan,
                                    Tosashimizu, Japan (solo)1992
                                </li>
                                <li>
                                    Ashizuri Art Exhibition, Kochi Pref, Japan
                                    1991
                                </li>
                                <li>Tosashimizu, Kochi, Japan 1990</li>
                                <li>
                                    Town of Fairhaven presented Emperor Akihito
                                    with Arthur’s work currently on display at
                                    the Imperial Palace in Tokyo, Japan 1987
                                </li>
                                <li>
                                    Cove Gallery exhibit Wellfleet,Ma (solo)
                                </li>
                            </ul>
                            <br />
                            <h3>
                                <u>Dedications</u>
                            </h3>
                            <br />
                            <p>
                                For the past 10 years Cheryl and Arthur raised
                                thousands for the Neediest Family Fund
                            </p>
                            <br />
                            <p>
                                In Memory “Create “ at Buttonwood Park is a
                                designated area for children to draw, with
                                drawing tables dedicated to Arthur.
                            </p>
                            <br />
                        </Box>
                    </Paper>
                </Grid2>
            </Grid2>
            <Grid2
                container
                marginX={{xs: 1, sm: 2, md: 4, lg: 8}}
                marginY={{xs: 0, sm: 2, md: 4, lg: 8}}
                fontSize={fontSizes}
            >
                <Grid2 xs={12} sm={8} marginY={{xs: 1, sm: 2, md: 4, lg: 8}}>
                    <Paper elevation={4} align="center">
                        <Box padding={{xs: 1, sm: 2}}>
                            <h3>The Arthur Moniz Scholarship Fund</h3>
                            <p>
                                is a scholarship being offered in memory of
                                Arthur Moniz who was a graduate of New Bedford
                                High School, class of 1962.
                            </p>
                            <br />
                            <p>
                                Arthur always attributed his great interest and
                                love of art to an incredibly dedicated art
                                teacher at New Bedford High School. He supported
                                Arthur’s decision to hone his artistic talents
                                and to pursue his dream, by attending the
                                Massachusetts College of Art in Boston.
                            </p>
                            <br />
                        </Box>
                    </Paper>
                </Grid2>
                <Grid2
                    xs={12}
                    sm={4}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box align="center">
                        <img
                            src="https://res.cloudinary.com/dtrqcot5a/image/upload/v1696217235/gallery/ef6700naahiicz6mrwby.webp"
                            alt="painting"
                            width="60%"
                        />
                    </Box>
                </Grid2>
            </Grid2>
        </main>
    );
}

export default AboutUs;
