import axios from "axios";
import {useEffect, useState} from "react";
import Select from "react-select";
import ItemPricingComponent from "./ItemPricingComponent";
import {TextField, Button, Box} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import Alerts from "../Alerts";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const options = [
    {value: "original", label: "Original"},
    {value: "printgallery", label: "Boats"},
    {value: "fishingboat", label: "Fishing Boats"},
    {value: "noteprint", label: "Note Print"},
    {value: "whaler", label: "Whaler and Ships"},
    {value: "coastal", label: "Coastal Scenes"},
    {value: "lighthouses", label: "Lighthouses"},
    {value: "whalermorgan", label: "Whaler Charles W. Morgan"},
    {value: "nature", label: "Nature & Wildlife"},
    {value: "cranberry", label: "Cranberry Bogs"},
    {value: "country", label: "Country Scenes"},
    {value: "garden", label: "Garden & Floral"},
    {value: "buttonwood", label: "Buttonwood Park"},
    {value: "azores", label: "Azores"},
    {value: "canterbury", label: "Canterbury Shaker Village"},
    {value: "florida", label: "Florida & Japan"},
    {value: "pen", label: "Pen & Ink"},
    {value: "martha", label: "Martha's Vineyard & Nantucket"},
    {value: "fallriver", label: "Fall River & Dartmouth"},
    {value: "newbedford", label: "New Bedford & Fairhaven"},
    {value: "matta", label: "Mattapoisett & Westport"},
    {value: "marion", label: "Marion & Acushnet"},
    {value: "gifts", label: "Other Gifts"},
    {
        value: "community",
        label: "Communities, Building, Churches, Schools, Misc",
    },
];

const availableOptions = [
    {value: false, label: "No"},
    {value: true, label: "Yes"},
];

function ItemPage({item}) {
    const {user} = useAuth0();
    const [alertMessage, setAlertMessage] = useState("");
    const [severityAlert, setSeverityAlert] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [newItem, setNewItem] = useState(
        item === "newItem"
            ? {
                  Name: "",
                  Description: "",
                  Type: "",
                  Available: false,
                  Pricing: [],
                  ImageUrl: "",
                  ItemId: 0,
                  Special: false,
              }
            : item
    );
    const [itemPricingArray, setItemPricingArray] = useState(
        item === "newItem" ? [] : item.Pricing
    );
    const [selectedOption, setSelectedOption] = useState(
        newItem.Type === "" ? "original" : newItem.Type
    );
    const [selectedAvailableOption, setSelectedAvailableOption] = useState(
        newItem.Available === false ? false : true
    );

    const [specialItem, setSpecialItem] = useState(
        newItem.Special === undefined
            ? (newItem.Special = false)
            : newItem.Special === false
            ? false
            : true
    );
    const [selectedFile, setSelectedFile] = useState("");

    function setShowAlertToFalse() {
        setShowAlert(false);
    }

    function clearAllValues() {
        setNewItem({
            Name: "",
            Description: "",
            Type: "",
            Available: false,
            Pricing: [],
            ImageUrl: "",
            ItemId: 0,
            Special: false,
        });
        setSelectedFile("");
    }

    function addRow() {
        var numberId = itemPricingArray.length + 1;
        var itemPrice = {
            PricingId: numberId,
            Dimensions: "",
            Price: 0,
            Discount: 0,
        };
        setItemPricingArray([...itemPricingArray, itemPrice]);
    }

    function removeRow(pricingId) {
        setItemPricingArray(
            itemPricingArray.filter(
                (pricing) => pricing.PricingId !== pricingId
            )
        );
    }

    function updatePricing(pricingId, dimension, price, discount) {
        try {
            var itemPrice = {
                PricingId: pricingId,
                Dimensions: dimension,
                Price: price,
                Discount: discount,
            };
            setItemPricingArray(
                itemPricingArray.map((item) => {
                    if (item.PricingId === itemPrice.PricingId) {
                        return {
                            ...item,
                            Price: itemPrice.Price,
                            Discount: itemPrice.Discount,
                            Dimensions: itemPrice.Dimensions,
                        };
                    }
                    return item;
                })
            );
            setShowAlert(true);
            setSeverityAlert("success");
            setAlertMessage("Pricing Updated!");
        } catch (error) {
            setShowAlert(true);
            setSeverityAlert("error");
            setAlertMessage(
                "There was an issue with updating pricing, please check the inputs"
            );
        }
    }

    const onChange = (evt) => {
        const key = evt.target.name;
        var value = evt.target.value;
        setNewItem((oldData) => ({...oldData, [key]: value}));
    };

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                newItem.ImageUrl = baseURL;
                resolve(baseURL);
            };
        });
    };

    const onFileUpload = (event) => {
        setSelectedFile(event.target.files[0]);
        getBase64(event.target.files[0]);
    };

    //Add or Update
    const onSubmit = () => {
        const config = {
            headers: {
                "Content-Type": "application/JSON",
                "x-api-key": user === undefined ? "" : user.sub,
            },
        };
        newItem.Pricing = itemPricingArray;
        newItem.Available =
            selectedAvailableOption.value === undefined
                ? item === "newItem"
                    ? false
                    : item.Available
                : selectedAvailableOption.value;
        newItem.Special =
            specialItem.value === undefined
                ? item === "newItem"
                    ? false
                    : item.Special
                : specialItem.value;
        newItem.Type =
            selectedOption.value === undefined
                ? item === "newItem"
                    ? "original"
                    : item.Type
                : selectedOption.value;
        if (newItem.ItemId === 0) {
            setShowAlert(true);
            setSeverityAlert("success");
            setAlertMessage(`${newItem.Name} has been updated!`);
            clearAllValues();
            axios
                .post(`${API_ENDPOINT}/item`, JSON.stringify(newItem), config)
                .then((response) => {
                    setShowAlert(true);
                    setSeverityAlert("success");
                    setAlertMessage(`${newItem.Name} has been created!`);
                    clearAllValues();
                })
                .catch((error) => {
                    setShowAlert(true);
                    setSeverityAlert("error");
                    setAlertMessage(
                        `${newItem.Name} has had an issue updating, if you continue experiencing a problem please contact us`
                    );
                });
        }
        if (newItem.ItemId !== 0) {
            axios
                .post(`${API_ENDPOINT}/item`, JSON.stringify(newItem), config)
                .then((response) => {
                    setShowAlert(true);
                    setSeverityAlert("success");
                    setAlertMessage(`${item.Name} has been updated!`);
                    clearAllValues();
                })
                .catch((error) => {
                    setShowAlert(true);
                    setSeverityAlert("error");
                    setAlertMessage(
                        `${newItem.Name} has had an issue updating, if you continue experiencing a problem please contact us`
                    );
                });
        }
    };

    useEffect(() => {}, [selectedOption, itemPricingArray]);

    useEffect(() => {}, [newItem]);

    return (
        <div className="admin-item-container">
            <label>Name: </label>
            <TextField
                fullWidth
                value={newItem.Name}
                name="Name"
                onChange={onChange}
                required
            ></TextField>
            <br />
            <label>Description:</label>
            <TextField
                fullWidth
                value={newItem.Description}
                name="Description"
                onChange={onChange}
                required
            ></TextField>
            <br />
            <label>Type:</label>
            <Select
                defaultValue={options.find(
                    (option) => option.value === selectedOption
                )}
                name="Type"
                onChange={setSelectedOption}
                options={options}
            />
            <label>Available for Sale:</label>
            <Select
                defaultValue={availableOptions.find(
                    (option) => option.value === selectedAvailableOption
                )}
                name="Available"
                onChange={setSelectedAvailableOption}
                options={availableOptions}
            />
            <label>Specialty Item:</label>
            <Select
                defaultValue={availableOptions.find(
                    (option) => option.value === specialItem
                )}
                name="Special"
                onChange={setSpecialItem}
                options={availableOptions}
            />
            <br />
            <label>Pricing:</label>
            <br />
            <Button onClick={() => addRow()}>Add Row</Button>
            {itemPricingArray.map((pricing) => {
                return (
                    <ItemPricingComponent
                        key={pricing.PricingId}
                        pricing={pricing}
                        removeRow={removeRow}
                        pricingId={pricing.PricingId}
                        updatePricing={updatePricing}
                    />
                );
            })}
            <br />
            <label>Image:</label>
            <img
                src={
                    selectedFile === ""
                        ? item.ImageUrl === ""
                            ? "nothing"
                            : item.ImageUrl
                        : URL.createObjectURL(selectedFile)
                }
                alt="nothing"
            />
            <Box marginY={2}>
                <input type="file" onChange={onFileUpload}></input>
            </Box>
            <Button variant="contained" onClick={onSubmit}>
                {newItem.ItemId === 0 ? "Create New Item" : "Update Item"}
            </Button>

            {showAlert && (
                <Alerts
                    severityAlert={severityAlert}
                    alertMessage={alertMessage}
                    openStatus={true}
                    setOpenToFalse={setShowAlertToFalse}
                />
            )}
        </div>
    );
}

export default ItemPage;
