import React from "react";
import {useShoppingListUpdater} from "./shoppingcart/ShoppingList";
import {useGetWeeklySpecialText} from "./shoppingcart/RetrieveItemsFromAxios";
import {FormControl, NativeSelect, Box} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

const reload = () => {
    window.location.reload();
};

export default function SpecialItems({specialItem}) {
    const {UpdateShoppingList} = useShoppingListUpdater();
    const weeklySpecialBodyText = useGetWeeklySpecialText();

    function addNewItem(item, pricingId) {
        if (item.Pricing.length > 1) {
            var pricing = item.Pricing.filter(
                (price) => price.PricingId == pricingId.value
            );
            item.Pricing = pricing;
        }
        UpdateShoppingList("add", "add", item);
        reload();
    }

    function changeSelected(e) {
        const selectedValue = document.querySelector(`#${e.target.id}`);
        selectedValue.value = e.target.value;
    }
    return (
        <>
            {specialItem !== undefined && (
                <Box
                    marginX={{xs: 1, sm: 2, md: 4, lg: 8}}
                    marginY={{xs: 2, sm: 4, md: 6, lg: 8}}
                    sx={{border: 4}}
                >
                    <Grid2
                        container
                        spacing={2}
                        marginX={2}
                        marginY={2}
                        padding={2}
                        sx={{border: 8, borderColor: "gray"}}
                    >
                        <Grid2 xs={12} sm={9} md={7} lg={6}>
                            <Box
                                padding={{xs: 0, sm: 2, md: 4}}
                                align="center"
                                textAlign="center"
                            >
                                <div className="special-left">
                                    <h2>SPECIAL PRICING</h2>
                                    <h3>This Week Only!</h3>
                                    <hr style={{color: "lightgray"}} />
                                    <p className="limited">
                                        {weeklySpecialBodyText !== ""
                                            ? weeklySpecialBodyText.weeklySpecialBody
                                            : "Items on Sale"}
                                    </p>
                                    <br />
                                    <div>
                                        {specialItem.Pricing.length === 1 && (
                                            <>
                                                {specialItem.Pricing.map(
                                                    (pricing) => {
                                                        return (
                                                            <p
                                                                key={
                                                                    pricing.PricingId
                                                                }
                                                                id="itemPricing"
                                                                value={
                                                                    pricing.PricingId
                                                                }
                                                            >{`${
                                                                pricing.Dimensions
                                                            } - $${(
                                                                pricing.Price -
                                                                (pricing.Price *
                                                                    pricing.Discount) /
                                                                    100
                                                            ).toFixed(2)}`}</p>
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                        {specialItem.Pricing.length > 1 && (
                                            <FormControl>
                                                <NativeSelect
                                                    defaultValue={1}
                                                    id={specialItem.Name.replaceAll(
                                                        " ",
                                                        ""
                                                    )}
                                                    onChange={changeSelected}
                                                >
                                                    {specialItem.Pricing.map(
                                                        (pricing) => {
                                                            return (
                                                                <option
                                                                    key={
                                                                        pricing.PricingId
                                                                    }
                                                                    value={
                                                                        pricing.PricingId
                                                                    }
                                                                >
                                                                    {
                                                                        pricing.Dimensions
                                                                    }{" "}
                                                                    - $
                                                                    {(
                                                                        pricing.Price -
                                                                        (pricing.Price *
                                                                            pricing.Discount) /
                                                                            100
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </NativeSelect>
                                            </FormControl>
                                        )}
                                        {!specialItem.Available && (
                                            <p>
                                                <b>Sold</b>
                                            </p>
                                        )}
                                        {specialItem.Available && (
                                            <button
                                                className="button"
                                                onClick={() =>
                                                    addNewItem(
                                                        specialItem,
                                                        document.querySelector(
                                                            `#${specialItem.Name.replaceAll(
                                                                " ",
                                                                ""
                                                            )}`
                                                        ) === null
                                                            ? 1
                                                            : document.querySelector(
                                                                  `#${specialItem.Name.replaceAll(
                                                                      " ",
                                                                      ""
                                                                  )}`
                                                              )
                                                    )
                                                }
                                            >
                                                Add to Cart
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Box>
                        </Grid2>
                        <Grid2 xs={12} sm={3} md={5} lg={6}>
                            <Box>
                                <div className="special-right">
                                    <img
                                        src={specialItem.ImageUrl}
                                        alt={specialItem.Name}
                                    />
                                </div>
                                <h2 className="special-right-h2">
                                    {specialItem.Name}
                                </h2>
                            </Box>
                        </Grid2>
                    </Grid2>
                </Box>
            )}
        </>
    );
}
