import {useState, useEffect} from "react";
import ItemCollection from "./shoppingcart/ItemCollection";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

function PrintGallery() {
    const [galleryType, setGalleryType] = useState("printgallery");

    function changeType(e) {
        setGalleryType(e.target.value);
    }

    useEffect(() => {}, [galleryType]);

    return (
        <main>
            <Grid2 container>
                <Grid2
                    xs={12}
                    sm={12}
                    md={10}
                    order={{xs: 2, sm: 2, md: 1}}
                    textAlign={"center"}
                >
                    <div className="original-gallery">
                        <h3>Print Gallery</h3>
                        <h4>
                            WE DO CUSTOM FRAMING! Call our gallery for more
                            information, 508-997-8644
                        </h4>
                        <div className="original-gallery-items">
                            <ItemCollection itemType={galleryType} />
                        </div>
                    </div>
                </Grid2>
                <Grid2
                    xs={12}
                    sm={12}
                    md={2}
                    marginY={{xs: 0, sm: 30}}
                    marginX={{xs: 5, sm: -5, md: 0}}
                    order={{xs: 1, sm: 1, md: 2}}
                >
                    <FormControl variant="outlined">
                        <FormLabel id="cateogry-radio-button-group">
                            Other works
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="cateogry-radio-button-group"
                            defaultValue="printgallery"
                            name="radio-buttons-group"
                            onChange={changeType}
                        >
                            <FormControlLabel
                                value="printgallery"
                                control={<Radio />}
                                label="Boats"
                            />
                            <FormControlLabel
                                value="fishingboat"
                                control={<Radio />}
                                label="Fishing Boats"
                            />
                            <FormControlLabel
                                value="whaler"
                                control={<Radio />}
                                label="Whaler and Ships"
                            />
                            <FormControlLabel
                                value="coastal"
                                control={<Radio />}
                                label="Coastal Scenes"
                            />
                            <FormControlLabel
                                value="lighthouses"
                                control={<Radio />}
                                label="Lighthouses"
                            />
                            <FormControlLabel
                                value="whalermorgan"
                                control={<Radio />}
                                label="Whaler Charles W. Morgan"
                            />
                            <FormControlLabel
                                value="nature"
                                control={<Radio />}
                                label="Nature & Wildlife "
                            />
                            <FormControlLabel
                                value="cranberry"
                                control={<Radio />}
                                label="Cranberry Bogs"
                            />
                            <FormControlLabel
                                value="country"
                                control={<Radio />}
                                label="Country Scenes"
                            />
                            <FormControlLabel
                                value="garden"
                                control={<Radio />}
                                label="Garden & Floral"
                            />
                            <FormControlLabel
                                value="buttonwood"
                                control={<Radio />}
                                label="Buttonwood Park"
                            />
                            <FormControlLabel
                                value="azores"
                                control={<Radio />}
                                label="Azores"
                            />
                            <FormControlLabel
                                value="canterbury"
                                control={<Radio />}
                                label="Canterbury Shaker Village"
                            />
                            <FormControlLabel
                                value="florida"
                                control={<Radio />}
                                label="Florida & Japan"
                            />
                            <FormControlLabel
                                value="pen"
                                control={<Radio />}
                                label="Pen & Ink"
                            />
                            <FormControlLabel
                                value="martha"
                                control={<Radio />}
                                label="Martha's Vineyard & Nantucket"
                            />
                            <FormControlLabel
                                value="fallriver"
                                control={<Radio />}
                                label="Fall River & Dartmouth"
                            />
                            <FormControlLabel
                                value="newbedford"
                                control={<Radio />}
                                label="New Bedford & Fairhaven"
                            />
                            <FormControlLabel
                                value="matta"
                                control={<Radio />}
                                label="Mattapoisett & Westport"
                            />
                            <FormControlLabel
                                value="marion"
                                control={<Radio />}
                                label="Marion & Acushnet"
                            />
                            <FormControlLabel
                                value="community"
                                control={<Radio />}
                                label="Communities, Building, Churches, Schools, Misc"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid2>
            </Grid2>
        </main>
    );
}

export default PrintGallery;
