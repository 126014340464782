import {useAuth0} from "@auth0/auth0-react";
import {Grid, Box, Button} from "@mui/material";

function AdminLogin() {
    return (
        <main>
            <Grid container alignItems="center">
                <Grid xs={12} margin={{xs: 16, sm: 36, md: 48, lg: 60}}>
                    <Box align="center">
                        <LoginButton />
                    </Box>
                </Grid>
            </Grid>
        </main>
    );
}

const LoginButton = () => {
    const {loginWithRedirect} = useAuth0();
    return (
        <Button variant="contained" onClick={() => loginWithRedirect()}>
            Log In
        </Button>
    );
};

export default AdminLogin;
