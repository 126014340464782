import {useState} from "react";

function CustomSearch({itemList, searchFunction}) {
    const [filteredData, setFilteredData] = useState([]);

    const searchItems = (searchValue) => {
        const data = itemList.filter((item) => {
            return item.Name.toLowerCase().match(
                `${searchValue.toLowerCase()}*`
            );
        });
        setFilteredData(data);
    };
    function setSearchFunction(input) {
        if (input === null) {
            var searchInput = document.getElementById("searchInput");
            if (searchInput.value !== "") {
                searchInput.value = "";
                searchFunction(null);
            }
            return null;
        }
        return searchFunction(filteredData);
    }

    return (
        <div className="search-gallery">
            <input
                id="searchInput"
                className="input"
                type="text"
                onChange={(e) => searchItems(e.target.value)}
                placeholder="Search..."
            />
            <div className="buttons-search">
                <button
                    className="button"
                    onClick={() => {
                        setSearchFunction(filteredData);
                    }}
                >
                    Search
                </button>
                <button
                    className="button"
                    onClick={() => {
                        setSearchFunction(null);
                    }}
                >
                    Clear
                </button>
            </div>
        </div>
    );
}
export default CustomSearch;
